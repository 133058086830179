<template>
  <div class="input-phone" :class="{ 'has-error': props.hint?.variant === 'error' }">
    <span v-if="props.label" class="label">
      {{ props.label }}<span v-if="props.isRequired" class="required">*</span>
    </span>

    <form-input-dropdown
      v-model:value="codeValue"
      :options="selectItems"
      :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.phoneCode.placeholder`)"
      name="phoneCode"
      :isShowName="true"
      @focus="onFocus"
      @input="onSelectInput"
    />

    <client-only>
      <input
        v-model="numberValue"
        v-maska="'############'"
        inputmode="numeric"
        class="field"
        type="text"
        name="phoneNumber"
        :placeholder="props.placeholder"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
      >
    </client-only>

    <atomic-hint v-if="hint" v-bind="hint"/>
  </div>
</template>

<script setup lang="ts">
  import parsePhoneNumber from 'libphonenumber-js';
  import { storeToRefs } from 'pinia';
  import type { IPhoneCode } from '@skeleton/types';
  import type { IFieldHint } from '@skeleton/types';

  interface IProps {
    label?: string;
    value?: string;
    isRequired?: boolean;
    placeholder?: string;
    hint?: IFieldHint;
  }

  const props = withDefaults(defineProps<IProps>(), {
    label: '',
    isRequired: false,
    placeholder: 'Enter number'
  });

  const globalStore = useGlobalStore();
  const {
    fieldsSettings,
    defaultLocaleFieldsSettings
  } = storeToRefs(globalStore);

  const { getContent } = useProjectMethods();
  const { countries, headerCountry } = storeToRefs(globalStore);
  const profileStore = useProfileStore();
  const { profile } = storeToRefs(profileStore);

  const selectItems:IPhoneCode[] = countries.value.map((country) => ({
    phonePrefix: country.phonePrefix,
    name: country.name,
    code: country.code,
    mask: `/img/flags/${country.code?.toLowerCase()}.svg`,
    value: `+${country.phonePrefix}`,
  })).sort((prevItem, nextItem) => {
    if (prevItem.code > nextItem.code) return 1;
    if (prevItem.code < nextItem.code) return -1;
    return 0;
  });
  const codeValue = ref('');
  const numberValue = ref('');

  const setMobileCode = (countryCode: string):void => {
    const searchPhone = selectItems.find((phoneObj) => phoneObj.code === countryCode);
    codeValue.value = searchPhone?.code || '';
  };

  const initPhoneCode = ():void => {
    if (props.value) {
      const parsePhone = parsePhoneNumber(`+${profile.value?.phone}`);

      if (parsePhone) {
        const searchPhone = selectItems.find((phoneObj) => phoneObj.phonePrefix === parsePhone.country);
        if (searchPhone?.code) {
          codeValue.value = searchPhone.code || '';
          numberValue.value = parsePhone.number.replace(searchPhone.code, '');
        }
      }
    } else if (profile.value?.country) setMobileCode(profile.value.country)
    else if (headerCountry.value) setMobileCode(headerCountry.value);
  };

  onMounted(() => {
    initPhoneCode();
  });

  const emit = defineEmits(['focus', 'input', 'update:value', 'blur']);
  const onFocus = ():void => {
    emit('focus');
  };


  const onInput = ():void => {
    if (codeValue.value && numberValue.value) {
      const selectedCountry = selectItems.find(phoneObj => phoneObj.code === codeValue.value);
      const fullPhoneNumber = selectedCountry?.phonePrefix as string + numberValue.value as string;
      emit('update:value', fullPhoneNumber);
      emit('input', fullPhoneNumber);
    } else {
      emit('update:value', '');
      emit('input', '');
    }
  };

  const onBlur = ():void => {
    emit('blur');
  };

  const onSelectInput = ():void => {
    onInput();
    onBlur();
  };
</script>

<style src="~/assets/styles/components/form/input/phone.scss" lang="scss" />
